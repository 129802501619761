import { useState,useEffect } from 'react';
import { All } from './components/all';
import { Active } from './components/active';
import { Completed } from './components/completed';
import PostToDoList from '../../Services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToDoList = () => {

    const [Tabs] = useState(["all","active","completed"]);
    const [CurrentTab,SetCurrentTab] = useState("all");
    const [ToDoTitle,SetToDo]  = useState("")
    const [Status,SetStatus] = useState("active");
    const [ToDoUpdId,SetToDoUpdId]  = useState(0)
    const [dummyToDo,SetdummyToDo] = useState([]);

    const [ActiveList, SetActiveList] = useState([]);
    const [AllList, SetAllList] = useState([]);
    const [CompletedList, SetCompletedList] = useState([]);

    const [Load,SetLoad] = useState(true);
    const [Submit,SetSubmit] = useState(false);
    const [SubmitUpdate,SetSubmitUpdate] = useState(false);
    const [SubmitDelete,SetSubmitDelete] = useState(false);


    const [ShowUpdateBtn,SetShowUpdateBtn] = useState(false);
    const HandleUpdate = (item) => {
        SetToDo(item.name)
        SetToDoUpdId(item.id)
        SetShowUpdateBtn(true)
    }

    const HandleInputTitle = (title) =>{
        if(title=== ""){
            SetShowUpdateBtn (false) 
        }
        SetToDo(title)
    }

    const HandleDelete = (item) => {
        SetToDoUpdId(item.id)
        SetSubmitDelete(true)
    }


    useEffect(()=>{
        if(Load){
            const InitiateSubmit = async () =>{
                return await new PostToDoList().Request({},"get-all")
            }
            InitiateSubmit().then((response)=>{
                if(response.status === 200){
                    const ListSeperator = (data) => {
                        let active = []
                        let completed = []
                        for(var item of data){
                            if(item.status==="active"){
                                active.push(item)
                            }
                            if(item.status==="completed"){
                                completed.push(item)
                            }
                        }
                        SetActiveList(active.reverse())
                        SetCompletedList(completed.reverse())
                        SetAllList(active.concat(completed))
                        console.log(active)
                        console.log(active.concat(completed))
                    }
                    ListSeperator(response.data.data)
                    
                    SetLoad(false);
                }else{
                    toast(response.message);
                }
            }).catch((error)=>{
                console.log(error)
                toast("something went wrong before sending to server");

            })
            const InitiateDummy = async () =>{
                return await new PostToDoList().Request({},"dummy-todos")
            }
            InitiateDummy().then((response)=>{
                SetdummyToDo(response.data.todos)
                const randomInteger = (min, max) => {
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                }
                SetToDo(response.data.todos[randomInteger(0,29)].todo)
            }).catch((error)=>{
                console.log(error)
                toast("something went wrong before sending to server");
            })
            console.log(dummyToDo)
            SetLoad(false);
        }
        if(Submit){
            const CheckEntry = () =>{
                return ToDoTitle.split("").length >= 8 ? true : false
            }
            if(CheckEntry()){
                const InitiateSubmit = async () =>{
                    return await new PostToDoList().Request({
                        todo : ToDoTitle,
                        status : Status
                    },"create")
                }

                InitiateSubmit().then((response)=>{
                    console.log(response)
                    if(response.status === 200){
                        SetToDo("");
                        SetLoad(true);
                        SetSubmit(false);
                    }else{
                        toast(response.data.message);
                        SetSubmit(false);
                    }
                }).catch((error)=>{
                    console.log(error)
                    toast("something went wrong before sending to server");
                    SetSubmit(false);

                })
            }else{
                toast("Reminder: lease enter atleast 8 character!");
                SetSubmit(false);
            }
            SetSubmit(false);
        }
        if(SubmitUpdate){
            const CheckEntry = () =>{
                return ToDoTitle.split("").length >= 8 ? true : false
            }
            if(CheckEntry()){
                const InitiateSubmit = async () =>{
                    return await new PostToDoList().Request({
                        id : ToDoUpdId,
                        todo : ToDoTitle,
                        status : Status
                    },"update")
                }

                InitiateSubmit().then((response)=>{
                    if(response.status === 200){
                        SetToDo("");
                        SetLoad(true);
                        SetShowUpdateBtn(false)
                        SetStatus("active")
                        SetSubmitUpdate(false);
                    }else{
                        toast(response.data.message);
                        SetSubmitUpdate(false);
                    }
                }).catch((error)=>{
                    console.log(error)
                    toast("something went wrong before sending to server");
                    SetSubmitUpdate(false);
                })
            }else{
                toast("Reminder: lease enter atleast 8 character!");
                SetSubmitUpdate(false);
            }
            SetSubmit(false);
        }
        if(SubmitDelete){
            const InitiateSubmit = async () =>{
                return await new PostToDoList().Request({
                    id : ToDoUpdId,
                    todo : ToDoTitle,
                    status : Status
                },"delete")
            }

            InitiateSubmit().then((response)=>{
                if(response.status === 200){
                    SetToDo("");
                    SetLoad(true);
                    toast("To do list has been deleted");
                    SetSubmitDelete(false)
                }else{
                    toast("Something went wrong status does not return ok");
                    SetSubmitDelete(false)
                }
            }).catch((error)=>{
                console.log(error)
                toast("Something went wrong before sending to server");
                SetSubmitDelete(false)
            })
            SetSubmitDelete(false)
        }
    },[Load,Submit,SubmitUpdate,SubmitDelete,Status,ToDoTitle,ToDoUpdId,dummyToDo])

    return (
        <>
            <section className="vh-100 gradient-custom">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="card">
                                <div className="card-body p-5">
                                    
                                        <div className="d-flex justify-content-center align-items-center mb-4">
                                            <div className="form-outline flex-fill">
                                                <input type="text" id="form2" className={"form-control" + (ShowUpdateBtn ? " border border-success" : "")} placeholder="New task" value={ToDoTitle} onChange={(e)=>{
                                                       HandleInputTitle(e.target.value)
                                                    }}/>
                                            </div>
                                            {
                                                !ShowUpdateBtn ? 
                                                    <button type="submit" 
                                                        className="btn btn-info ms-2" 
                                                        onClick={()=>{SetSubmit(true)}} 
                                                        disabled={Submit ? true: false}>
                                                            {Submit ? "Loading": "Add"}
                                                    </button>
                                                    :
                                                    <>
                                                    <select className="browser-default custom-select" style={{width:"100px"}} onChange={(e)=>{SetStatus(e.target.value)}}>
                                                        <option value="">Status</option>
                                                        <option value="active">Active</option>
                                                        <option value="completed">Completed</option>
                                                    </select>
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-success ms-2" 
                                                        onClick={()=>{SetSubmitUpdate(true)}}
                                                        disabled={SubmitUpdate ? true: false}>
                                                            {SubmitUpdate ? "Loading": "Update"}
                                                        </button>
                                                    </>
                                            }
                                        </div>

                                        <ul className="nav nav-tabs mb-4 pb-2" id="ex1" role="tablist">

                                            {Tabs.map((data,index)=>{
                                                return(
                                                    <li className="nav-item" role="presentation" onClick={()=>{
                                                        SetCurrentTab(data) 
                                                        SetShowUpdateBtn(false)
                                                        SetStatus("active")}} 
                                                        key={index}>
                                                        <a className={"nav-link " + (CurrentTab === data ? "active" : "") } 
                                                            id="ex1-tab-1" data-mdb-toggle="tab" 
                                                            role="tab" 
                                                            aria-controls="ex1-tabs-1"
                                                            aria-selected="true">
                                                            {data.toUpperCase()}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>

                                        <div className="tab-content" id="ex1-content" style={{height:"300px",overflow:"scroll"}}>
                                            {
                                                CurrentTab === "all" ? 
                                                <All allList={AllList}/> : 

                                                CurrentTab === "active" ? 
                                                <Active 
                                                    activeList={ActiveList} 
                                                    handleUpdate={HandleUpdate} 
                                                    handleDelete={HandleDelete}/> : 

                                                CurrentTab === "completed" ? 
                                                <Completed 
                                                    completedList={CompletedList} 
                                                    handleDelete={HandleDelete} 
                                                    handleUpdate={HandleUpdate}/> : ""
                                            }
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </section>
        </>
    );
  }