import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {Modal, Row,Col, Table,Container, Button, Form,Spinner} from 'react-bootstrap'
import { setCSRF } from '../helper';

export default class CrudModal extends React.Component {

  constructor () {
    super();
    this.state  = {
        showInsertModal : false,
        showDeleteModal: false,
        showUpdateModal:false,
        holdDeleteId : "",
        holdUpdateId: "",
        firstName:'',
        lastName:'',
        email:'',
        isLoading: false,
        users:[],
        apiUrl : process.env.REACT_APP_API_URL
    }
  }

   validateEmail = (email) => {
        this.setState({email:email})
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(this.state.email.match(mailformat))
        {
            document.querySelector("#email-crud").style.borderColor = "green"
            return true;
        }
        else
        {  
            document.querySelector("#email-crud").style.borderColor = "red"
            return false;
        }
    }
    submit = async()=>{
        if(this.validateEntry() && this.validateEmail(this.state.email)){
            const {firstName,lastName,email} = this.state;
            this.setState({isLoading:true})
            const body = {
                firstName:firstName,
                lastName:lastName,
                email:email,
            }
            const credentials = {
                headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
            }
            
            axios.post(this.state.apiUrl+'/api/v1/insert',body,credentials).then((res) => {
                if(res.status === 200){
                    this.setState(
                        {
                            isLoading:false,
                            users:res.data.users,
                            showInsertModal: false,
                            firstName: "",
                            lastName:"",
                            email:""
                        })
                    toast(res.data.message);
                }else if (res.status === 202){ //if 202, email is already inserted
                    toast(res.data.message);
                    this.setState({isLoading:false})
                    document.querySelector("#email-crud").style.borderColor ="red"
                }
            }).catch((err)=>{
                console.log("error message ==>",err);
            })

        }else if(this.state.email !== "" && this.validateEmail(this.state.email)){
            toast(`Email is not valid`)
        }
    }
    validateEntry =()=>{
        const {firstName,lastName,email} = this.state;
        const isGood = true;
        return firstName === "" ? this.triggerToast('First Name')
        : lastName === "" ? this.triggerToast('Last Name')
        : email === "" ? this.triggerToast('Email')
        : isGood;
    }
    triggerToast =(keyWord)=>{
        toast(`${keyWord} cannot be empty`)
        return false;
    }
    componentDidMount = async () =>{
        try {
            const {firstName,lastName,email} = this.state;
            let data = await axios(this.state.apiUrl+'/api/v1/records');
            console.log("data==>",data.data.users)
            this.setState({users:data.data.users});
            console.log("cookie before sending ====>",document.cookie);
            
        } catch (error) {
            console.log(error)
        }
    }
    handleId = (id,type,firstName,lastName,email) =>{
        type === "delete" ? 
            this.setState({showDeleteModal:true,holdDeleteId:id}) 
        : type ==="update" ?
            this.setState({showUpdateModal:true,holdUpdateId:id, firstName:firstName,lastName:lastName,email:email})
        : toast("No Handle type found");
    }
    submitDelete = async ()=>{
        const {firstName,lastName,email,holdDeleteId} = this.state;
        this.setState({isLoading:true})
        const body = {
            id:holdDeleteId,
        }
        const credentials = {
            headers: {'Access-Control-Allow-Origin': '*'}
        }
        
        axios.post(this.state.apiUrl+'/api/v1/delete',body,credentials).then(async(res) => {
            if(res.status === 200){
                this.setState(
                    {
                        users:res.data.users,
                        isLoading:false,
                        showDeleteModal: false,
                    });
                toast(res.data.message);
            }else{
                toast(res.data.message);
                this.setState({isLoading:false})
                document.querySelector("#email-crud").style.borderColor ="red"
            }
        }).catch((err)=>{
            console.log("error message ==>",err);
        })
    }
    submitUpdate = async () => {
        if(this.validateEntry() && this.validateEmail(this.state.email)){
            const {firstName,lastName,email,holdUpdateId} = this.state;
            this.setState({isLoading:true})
            const body = {
                firstName:firstName,
                lastName:lastName,
                email:email,
                id:holdUpdateId
            }
            const credentials = {
                headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
            }
            axios.post(this.state.apiUrl+'/api/v1/update',body,credentials).then(async(res) => {
                if(res.status === 200){
                    this.setState({
                            users:res.data.users,
                            isLoading:false,
                            showUpdateModal: false,
                            firstName: "",
                            lastName:"",
                            email:""
                        });
                    toast(res.data.message);
                }else if (res.status >= 202){ //if 202, email is already inserted
                    toast(res.data.message);
                    this.setState({isLoading:false})
                    document.querySelector("#email-crud").style.borderColor ="red"
                }
            }).catch((err)=>{
                console.log("error message ==>",err);
            })

        }else if(this.state.email !== "" && this.validateEmail(this.state.email)){
            toast(`Email is not valid`)
        }
    }

    submitGenerate = async() => {
        let generated = await axios.post(this.state.apiUrl+'/generate-report');
    }

    render(){
        const userList = this.state.users;
        return(
            <>
            <Modal show={this.props.showCrudButton} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={()=>{this.props.parentCallBack(false)}}>
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Button onClick={()=>{this.setState({showInsertModal:true})}}>Add Record</Button>
                        {/* <Button variant="success" onClick={()=>{this.submitGenerate()}}>Generate Report</Button> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Username</th>
                                    <th>Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {userList.map((item,index)=>{
                                    return  <tr key={index}>
                                                <td>{index+1}</td> 
                                                <td>{item.first_name}</td>    
                                                <td>{item.last_name}</td>    
                                                <td>{item.email}</td>
                                                <Button variant='info' onClick={()=>{this.handleId(item.id,"update",item.first_name,item.last_name,item.email)}}>Update</Button> 
                                                <Button variant='danger' onClick={()=>{this.handleId(item.id,"delete","","","")}}>Delete</Button>
                                            </tr>
                                    })}
                            </tbody>
                        </Table>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>{this.props.parentCallBack(false)}}>Close</Button>
                </Modal.Footer>
            </Modal>
            
            <Modal show={this.state.showInsertModal || this.state.showUpdateModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{backgroundColor:'black'}} onHide={()=>{this.setState({showInsertModal:false,showUpdateModal:false})}}> 
                <Modal.Header>
                    Add new Data (Limit of 10 data)
                </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={8} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>First Name</Form.Label>
                                    {
                                        this.state.showInsertModal 
                                            ? 
                                                <Form.Control type="text" id="firstName-crud" onChange={(e)=>{this.setState({firstName:e.target.value})}}/>
                                            : 
                                        this.state.showUpdateModal
                                            ?
                                                <Form.Control type="text" id="firstName-crud" value={this.state.firstName} onChange={(e)=>{this.setState({firstName:e.target.value})}}/>
                                            :
                                            ""
                                    }
                                </Form.Group>
                            </Col>
                            <Col xs={8} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Last Name</Form.Label> 
                                    {
                                        this.state.showInsertModal 
                                            ? 
                                                <Form.Control type="text" id="firstName-crud" onChange={(e)=>{this.setState({lastName:e.target.value})}}/>
                                            : 
                                        this.state.showUpdateModal
                                            ?
                                                <Form.Control type="text" id="firstName-crud" value={this.state.lastName} onChange={(e)=>{this.setState({lastName:e.target.value})}}/>
                                            :
                                            ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col xs={12} md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email Address</Form.Label>
                                    {
                                        this.state.showInsertModal 
                                            ? 
                                                <Form.Control type="text" id="email-crud" placeholder="" onChange={(e)=>{this.validateEmail(e.target.value)}}/>
                                            : 
                                        this.state.showUpdateModal
                                            ?
                                                <Form.Control type="text" value={this.state.email} id="email-crud" placeholder="" onChange={(e)=>{this.validateEmail(e.target.value)}}/>
                                            :
                                            ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.showInsertModal 
                            ? 
                                <Button variant='primary' disabled={this.state.isLoading} onClick={()=>{this.submit()}}>{this.state.isLoading ? <Spinner animation="grow" /> : "Save"}</Button> 
                            : 
                        this.state.showUpdateModal
                            ?
                            <Button variant='primary' disabled={this.state.isLoading} onClick={()=>{this.submitUpdate()}}>{this.state.isLoading ? <Spinner animation="grow" /> : "Update"}</Button> 
                            :
                            ""
                    }
                    
                    <Button variant='dark' onClick={()=>{this.setState({showInsertModal:false,showUpdateModal:false})}}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.showDeleteModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={()=>{this.setState({showDeleteModal:false})}}> 
                <Modal.Header>
                        <Row>
                            <Col>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Confirmation
                                </Modal.Title>
                            </Col>
                        </Row>
                </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                Are you sure you want to delete this item?
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary'disabled={this.state.isLoading} onClick={()=>{this.submitDelete()}}>{this.state.isLoading ? <Spinner animation="grow" /> : "Yes"}</Button>
                        <Button variant='dark' disabled={this.state.isLoading} onClick={()=>{this.setState({showDeleteModal:false})}}>No</Button>
                    </Modal.Footer>
            </Modal>    
            <ToastContainer />
            </>
        )
    }
   
}