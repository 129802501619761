import React from 'react';
import {Modal, Table, Button} from 'react-bootstrap'
import {ToastContainer, toast } from 'react-toastify';
import Confetti from 'react-confetti'
export default class TikTakToe extends React.Component {
    constructor(){
        super();
        this.state = {
            winner : "",
            isWon : false,
            tiles : [0,1,2,3,4,5,6,7,8]
        }
    }
    
    render(){
        const styleTable = {
            position: "relative",
            minWidth: "80px",
            minHeight: "100px",
            height: "100%",
            border : "1px solid black",
            verticalAlign: "middle",
            textAlign: "center",
            fontSize: "-webkit-xxx-large"
        }
        const trSize = {
            height: "160px"
        }
        const isWon = this.state.isWon;
        const winner = this.state.winner;
        return(
        <>
            <Modal show={this.props.showTiTakToe} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={()=>{this.props.parentCallBack(false)}}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{textAlign:"center"}}>
                        {isWon ? `${winner} is the winner!` : "Match with AI"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                        <Table style={styleTable}>
                            <tr style={trSize}>
                                <td onClick={()=>{this.Tap(0)}} id="pos0">
                                    
                                </td>
                                <td onClick={()=>{this.Tap(1)}} id="pos1">
                                    
                                </td>
                                <td onClick={()=>{this.Tap(2)}} id="pos2">
                                    
                                </td>
                            </tr>
                            <tr style={trSize}>
                                <td onClick={()=>{this.Tap(3)}} id="pos3">
                                    
                                </td>
                                <td onClick={()=>{this.Tap(4)}} id="pos4">
                                    
                                </td>
                                <td onClick={()=>{this.Tap(5)}} id="pos5">
                                    
                                </td>
                            </tr>
                            <tr style={trSize}>
                                <td onClick={()=>{this.Tap(6)}} id="pos6">
                                    
                                </td>
                                <td onClick={()=>{this.Tap(7)}} id="pos7">
                                    
                                </td>
                                <td onClick={()=>{this.Tap(8)}} id="pos8">
                                    
                                </td>
                            </tr>
                        </Table>
                        <div style={{textAlign:"center", alignItems:"center"}}>
                            <Button onClick={()=>{this.Reset();}}>Reset</Button>
                            <Button onClick={()=>{this.Reset(); this.props.parentCallBack(false);}}>Close</Button>
                        </div>
                 
                
            {isWon === true ? <Confetti width={"800px"}/> : ""}
                </Modal.Body>
            </Modal>
            
            <ToastContainer />
        </>
        )
    }

    Reset = () =>{
        for(var x = 0; x <= 8; x++){
            let id = "#pos"+x.toString();
            let getElement =  document.querySelector(id);
            getElement.innerHTML= ""
            getElement.style = ""
        }
        this.setState({tiles:[0,1,2,3,4,5,6,7,8],isWon:false,winner:""})
    }
    Tap =(x)=>{
        try {
            if(!this.state.isWon) {
                if(this.Occupied(x))
                {   
                    let id = "#pos"+x.toString();
                    let getElement =  document.querySelector(id);
                        getElement.innerHTML= "O"
                        getElement.style = "background-color: lightgreen"
                    this.DeductTiles(x);
                    !this.CheckWinning(x) ?  this.PlayerAI() : this.Disable("You");
                    
                }else{
                    toast(`Already occupied`)
                }
            }else{
                toast(`${this.state.winner} already won, please restart the game`)
            }
        } catch (error) {
            toast(`Already occupied`)
        }
       
    }

    Occupied = (y) =>{
            console.log("Clicked tile",y);
            let result = true;
            let id = "#pos"+y.toString();
            let textValue = document.querySelector(id).innerText;
            if(textValue === "X" || textValue === "O"){
                result = false;
            }
        return result;
    }

    PlayerAI = () => {
        if(!this.state.isWon){
            const choice = this.state.tiles[Math.floor(Math.random()*this.state.tiles.length)];
            if(this.Occupied(choice))
            { 
                let id = "#pos"+choice.toString();
                let getElement =  document.querySelector(id);
                    getElement.innerHTML = "X"
                    getElement.style = "background-color: #fb5555"
                this.DeductTiles(choice);
                !this.CheckWinning(choice) ?  this.Draw() : this.Disable("AI");
            }else{
                this.PlayerAI();
            }
        }
    }
    CheckWinning = (choice) => {
        let id = "#pos"+choice.toString();
        let Marked =  document.querySelector(id).innerHTML;
        switch(choice){
            case 0: 
                if(document.querySelector("#pos1").innerHTML === Marked &&  document.querySelector("#pos2").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos3").innerHTML === Marked &&  document.querySelector("#pos6").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos4").innerHTML === Marked &&  document.querySelector("#pos8").innerHTML === Marked)
                return true;
            break;
            case 1: 
                if(document.querySelector("#pos0").innerHTML === Marked &&  document.querySelector("#pos2").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos4").innerHTML === Marked &&  document.querySelector("#pos7").innerHTML === Marked)
                return true;
            break;
            case 2: 
                if(document.querySelector("#pos0").innerHTML === Marked &&  document.querySelector("#pos1").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos5").innerHTML === Marked &&  document.querySelector("#pos8").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos4").innerHTML === Marked &&  document.querySelector("#pos6").innerHTML === Marked)
                return true;
            break;
            case 3: 
                if(document.querySelector("#pos0").innerHTML === Marked &&  document.querySelector("#pos6").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos4").innerHTML === Marked &&  document.querySelector("#pos5").innerHTML === Marked)
                return true;
            break;
            case 4: 
                if(document.querySelector("#pos0").innerHTML === Marked &&  document.querySelector("#pos8").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos1").innerHTML === Marked &&  document.querySelector("#pos7").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos2").innerHTML === Marked &&  document.querySelector("#pos6").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos3").innerHTML === Marked &&  document.querySelector("#pos5").innerHTML === Marked)
                return true;
            break;
            case 5:
                if(document.querySelector("#pos2").innerHTML === Marked &&  document.querySelector("#pos8").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos3").innerHTML === Marked &&  document.querySelector("#pos4").innerHTML === Marked)
                return true;
            break;
            case 6:
                if(document.querySelector("#pos0").innerHTML === Marked &&  document.querySelector("#pos3").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos7").innerHTML === Marked &&  document.querySelector("#pos8").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos4").innerHTML === Marked &&  document.querySelector("#pos2").innerHTML === Marked)
                return true;
            break;
            case 7:
                if(document.querySelector("#pos4").innerHTML === Marked &&  document.querySelector("#pos1").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos6").innerHTML === Marked &&  document.querySelector("#pos8").innerHTML === Marked)
                return true;
            break;
            case 8:
                if(document.querySelector("#pos2").innerHTML === Marked &&  document.querySelector("#pos5").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos0").innerHTML === Marked &&  document.querySelector("#pos4").innerHTML === Marked)
                return true;
                if(document.querySelector("#pos6").innerHTML === Marked &&  document.querySelector("#pos7").innerHTML === Marked)
                return true;
            break;
            default:
            break;
        }
    }

    Draw =()=>{
        const tiles = this.state.tiles;
        tiles.length <= 0 ? toast(`Draw, please restart the game`) :console.log("continue")
    }

    DeductTiles = (clicked) =>{
        for(var x = 0; x <= this.state.tiles.length; x++){
            if(clicked === this.state.tiles[x]){ this.state.tiles.splice(x,1)}
        }
        return this.state.tiles;
    }

    Disable(victorous){
        toast(`Thank you for playing! ${victorous} won!`);
        this.setState({isWon:true,winner:victorous})
    }

    componentDidUpdate(){
        if(this.props.showTiTakToe){
            this.PlayerAI();
        }   
    }
}