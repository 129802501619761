import React from 'react';
import {Modal, Row,Col,Container, Button, Form,Spinner} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import {setCSRF} from '../helper'
import axios from 'axios'
export default class ModalCrawler extends React.Component {

    constructor(){
        super();
        this.state = {
            link:"",
            title:"",
            content:"",
            crawledData : {},
            crawledComplete: false,
            isLoading: false,
            apiUrl : process.env.API_URL //https://abishai.tech
        }   
    }
    render(){
        return(
            <>
             <Modal show={this.props.showButton} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={()=>{this.props.parentCallBack(false)}}>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                               <p>Web crawling or web scraping is essential to use when there is no API provided by app that we are integrating to, but it's not always the best option, most of the times we face problems like recaptcha, recaptcha is created to detect and avoid bot like puppeteer or selenium.</p>
                            </Col>
                            <Col xs={12} md={12}>
                               <p>It's basically a bot that can open your browser automatically and go to a site where it gets the information using queryselector or xpath. it sounds so simple but like I said, it needs to be properly coded and we should have a function where it bypass recaptcha and possible blockage along the way.</p>
                            </Col>
                            <Col xs={12} md={12}>
                               <p>I have worked with web crawling before using puppeteer, thanks to awesome developers who developed such great library that we can use.</p>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button onClick={()=>{this.submit()}} disabled={this.state.isLoading}>{this.state.isLoading ? <Spinner animation="grow" /> : "Submit"}</Button> */}
                    <Button onClick={()=>{this.props.parentCallBack(false); this.setState({link:"",title:"",content:""})}}  disabled={this.state.isLoading}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.crawledComplete} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={()=>{this.setState({crawledComplete:false})}}>
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.crawledData.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        {this.state.crawledData.content}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>{this.setState({crawledComplete:false})}}>Close</Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            </>
        )
    }

    submit = async() =>{
        if(this.validateEntry()) {
            const {link,title,content} = this.state;
            //await  setCSRF(1,link,title,content);
            this.setState({isLoading:true})
            axios.post(this.state.apiUrl+'/api/v1/web-crawler',{
                link:link,
                title:title,
                content:content,
            },
            {
                headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
            }).then((res) => {
                if(res.data.data.status === 200){
                    this.setState({crawledComplete:true,crawledData:res.data.data,isLoading:false})
                }else if (res.data.data.status === 202){
                    toast(res.data.data.message);
                    this.setState({isLoading:false})
                    document.querySelector("#link").style.borderColor = "red"
                    document.querySelector("#title").style.borderColor = "red"
                    document.querySelector("#content").style.borderColor = "red"
                }
            }).catch((res)=>{
                toast(res.data.data.message);
            })
        }
    }

    validateEntry =()=>{
        const {link,title,content} = this.state;
        let res = true;
        link !== "" ? this.triggerToast ("uncolorlink") : res = this.checkWhichIsEmpty("Link");
        title !== "" ? this.triggerToast ("uncolortitle") : res = this.checkWhichIsEmpty("Title");
        content !== "" ? this.triggerToast ("uncolorcontent") : res = this.checkWhichIsEmpty("Content");
        return res;
    }

    checkWhichIsEmpty = (txtIndicator) =>{
        let keyWord = false;
        txtIndicator !== "" ? this.triggerToast(txtIndicator) : console.log()
        return keyWord;
    }

    triggerToast = (txtIndicator) =>{
        if(txtIndicator === 'Link'){
            document.querySelector("#link").style.borderColor = "red"
            toast(`${txtIndicator} cannot be empty`)}
        if(txtIndicator === 'Title'){
            document.querySelector("#title").style.borderColor = "red"
            toast(`${txtIndicator} cannot be empty`)}
        if(txtIndicator === 'Content'){
            document.querySelector("#content").style.borderColor = "red"
            toast(`${txtIndicator} cannot be empty`)}

        if(txtIndicator === 'uncolorlink')
            document.querySelector("#link").style.borderColor = "lightgreen"
        if(txtIndicator === 'uncolortitle')
            document.querySelector("#title").style.borderColor = "lightgreen"
        if(txtIndicator === 'uncolorcontent')
            document.querySelector("#content").style.borderColor = "lightgreen"
    }

    componentDidMount = async () =>{
        const {link,title,content} = this.state;
        //await setCSRF(1,link,title,content);
    }
}