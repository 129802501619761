import React from 'react';
import {Main} from './App';
import Header from './component/header'
import reportWebVitals from './reportWebVitals';
import MessengerCustomChat from 'react-messenger-customer-chat'
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header/>
      <Main />
      <MessengerCustomChat
        pageId="107964728337902"
      />
   </BrowserRouter> 
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();