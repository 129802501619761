import React from 'react';
import {getAboutData} from '../helper'

export default class ContactPage extends React.Component {

   constructor () {
       super();

       this.state = {
           fullName : "",
           emal:"",
           subject:"",
           message:"",
       }
       
   }

   SendEmail(){

      
   }

    render(){
        return(
            <>
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                        <h2>Contact</h2>
                        </div>

                        <div className="row mt-1">

                        <div className="col-lg-4">
                            <div className="info">
                                <div className="address">
                                    <i className="icofont-google-map"></i>
                                    <h4>Location:</h4>
                                    <p>{getAboutData.city}</p>
                                </div>

                                <div className="email">
                                    <i className="icofont-envelope"></i>
                                    <h4>Email:</h4>
                                    <p>{getAboutData.email}</p>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-8 mt-5 mt-lg-0">

                            <form className="php-email-form" onSubmit={this.SendEmail()}>
                            <input type="hidden" name="contact_number" />

                                <div className="form-row">

                                    <div className="col-md-6 form-group">
                                        <input type="text" name="fulllname" className="form-control" id="fulllname-contact" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars"onInput={(e)=>{this.setState()}} />
                                        <div className="validate"></div>

                                    </div>

                                    <div className="col-md-6 form-group">
                                        <input type="email" className="form-control" name="email" id="email-contact" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                                        <div className="validate"></div>
                                    </div>

                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                    <div className="validate"></div>
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                                    <div className="validate"></div>
                                </div>

                                <div className="mb-3">
                                    <div className="loading">Loading</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">Your message has been sent. Thank you!</div>
                                </div>
                                <div className="text-center">
                                    {/**<button type="submit" onClick={()=>{this.Submit()}}>Send Message</button> */}
                                    <input type="submit" value="Send" />
                                </div>
                            </form>

                            </div>

                        </div>

                    </div>
                </section>
            </>
            
        )
    }


    Submit(){

    }
}