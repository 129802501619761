import React from 'react';
import AboutPage from './AboutPage';
import ResumePage from './ResumePage';
import SamplePage from './SamplePage';
import ContactPage from './ContactPage';
import {getHomeData,getAboutData} from '../helper'
import 'react-toastify/dist/ReactToastify.css';


export default class HomePage extends React.Component {

    constructor () {
     super();    
        this.state = {
            job_title : getHomeData.jobtitle,
            profile_links : getHomeData.links,
            name: getHomeData.username,
            links: getHomeData.links
        }
    }
    render(){
        return(
        <>
            <section id="hero" className="d-flex flex-column justify-content-center">
                <div className="container" data-aos="zoom-in" data-aos-delay="100">
                    <h1>{this.state.name}</h1>
                    <p><span className="typed" data-typed-items= {this.state.job_title.map((element,index)=>{
                        return element
                    })}></span></p>
                   
                    <div className="social-links">
                        <a href={this.state.links.facebook} className="facebook" target="__blank"><i className="bx bxl-facebook"></i></a>
                        <a href={this.state.links.instagram} className="instagram" target="__blank"><i className="bx bxl-instagram"></i></a>
                        <a href={this.state.links.linkedIn} className="linkedin" target="__blank"><i className="bx bxl-linkedin"></i></a>
                        <a href={this.state.links.youtube} className="youtube" target="__blank"><i className="bx bxl-youtube"></i></a>

                    </div>
                </div>
            </section>
            {/* <AboutPage AboutData = {getAboutData}/> */}
            <ResumePage/>
            <SamplePage/>
            {/* <ContactPage/>             */}
        </>
        )
    }
}