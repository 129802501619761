import React from 'react';
import {getResumeData} from '../helper'
import { v4 as uuidv4 } from 'uuid';


export default class ResumePage extends React.Component {
    render(){
        return(
        <>
        <section id="resume" className="resume">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Resume</h2>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="resume-title">Summary</h3>
                        <div className="resume-item pb-0">
                            <h4>{getResumeData.username}</h4>
                            <p><em>{getResumeData.description}</em></p>
                            <ul>
                                <li key={uuidv4()}>{getResumeData.city}</li>
                                <li key={uuidv4()}>{getResumeData.email}</li>
                            </ul>
                        </div>

                        <h3 className="resume-title">Education</h3>
                        <div className="resume-item">
                            <h4>{getResumeData.education.college.course}</h4>
                            <h5>{getResumeData.education.college.year}</h5>
                            <p><em>{getResumeData.education.school}</em></p>
                            <p>{getResumeData.education.college.description}</p>
                            <p>source:</p>
                            <a target="_blank" rel="noreferrer" href={getResumeData.education.college.source}>School of Information Technology</a>
                        </div>
                        <div className="resume-item">
                            <h4>High School</h4>
                            <h5>{getResumeData.education.highSchool.year}</h5>
                            <p><em>{getResumeData.education.school}</em></p>
                            <p>{getResumeData.education.highSchool.description}</p>
                            <p>source:</p>
                            <a target="_blank" rel="noreferrer" href={getResumeData.education.highSchool.source}>School of Secondary Education</a>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <h3 className="resume-title">Professional Experience</h3>
                        <div className="resume-item">
                            <h4>{getResumeData.experience.novare.position}</h4>
                            <h5>{getResumeData.experience.novare.year}</h5>
                            <p><em>{getResumeData.experience.novare.location}</em></p>
                            <ul>
                                {getResumeData.experience.novare.tasks.map((element,index)=>{
                                    return <li key={uuidv4()}>{element}</li>
                                })}
                            </ul>
                        </div>
                        <div className="resume-item">
                            <h4>{getResumeData.experience.ourhiraya.position}</h4>
                            <h5>{getResumeData.experience.ourhiraya.year}</h5>
                            <p><em>{getResumeData.experience.ourhiraya.location}</em></p>
                            <ul>
                                {getResumeData.experience.ourhiraya.tasks.map((element,index)=>{
                                    return <li key={uuidv4()}>{element}</li>
                                })}
                            </ul>
                        </div>
                        <div className="resume-item">
                            <h4>{getResumeData.experience.a2bhq.position}</h4>
                            <h5>{getResumeData.experience.a2bhq.year}</h5>
                            <p><em>{getResumeData.experience.a2bhq.location}</em></p>
                            <ul>
                                {getResumeData.experience.a2bhq.tasks.map((element,index)=>{
                                    return <li key={uuidv4()}>{element}</li>
                                })}
                            </ul>
                        </div>
                        <div className="resume-item">
                            <h4>{getResumeData.experience.freelance.position}</h4>
                                <h5>{getResumeData.experience.freelance.year}</h5>
                                <p><em>{getResumeData.experience.freelance.location}</em></p>
                            <ul>
                                {getResumeData.experience.freelance.tasks.map((element,index)=>{
                                    return <li key={uuidv4()}>{element}</li>
                                })}
                            </ul>
                        </div>

                        <div className="resume-item">
                            <h4>{getResumeData.experience.alorica.position}</h4>
                                <h5>{getResumeData.experience.alorica.year}</h5>
                                <p><em>{getResumeData.experience.alorica.location}</em></p>
                            <ul>
                                {getResumeData.experience.alorica.tasks.map((element,index)=>{
                                    return <li key={uuidv4()}>{element}</li>
                                })}
                            </ul>
                        </div>

                        
                    </div>
                   
                </div>
            </div>
        </section>

        </>)
    }
}