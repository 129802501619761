import React from 'react';
import image from '../styles/assets/img/GRAD.jpg'


export default class AboutPage extends React.Component {

    constructor(){
        super();
        this.state={
            info : '',
        }
    }

    render(){
        const data = this.state.info
        return(
        <>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>About</h2>
                        <p>{data.description}</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <img src={image} className="img-fluid" alt=""></img>
                    </div>
                    <div className="col-lg-8 pt-4 pt-lg-0 content">
                        <h3>{data.job_title}</h3>
                        <p className="font-italic">
                            {data.short_description}
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                <li><i className="icofont-rounded-right"></i> <strong>Website:</strong> <a target="_blank"  rel="noreferrer" href={data.website}>eveguel</a></li>
                                <li><i className="icofont-rounded-right"></i> <strong>City:</strong> {data.city}</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                <li><i className="icofont-rounded-right"></i> <strong>Degree:</strong> {data.degree}</li>
                                <li><i className="icofont-rounded-right"></i> <strong>email:</strong> {data.email}</li>
                                <li><i className="icofont-rounded-right"></i> <strong>Freelance:</strong> {data.freelance}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
            </section>
        </>
        )
    }
    componentDidMount(){
        this.setState({info:this.props.AboutData})
        console.log(this.props.AboutData.description);
    }
}