
import './styles/assets/css/style.css'
import HomePage from '../src/pages/HomePage.jsx';
import { Routes, Route, Link } from "react-router-dom";
import AboutPage from './pages/AboutPage';


export const Main = () => {
  return(
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
      </Routes>
  )
}